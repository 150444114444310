// http://ionicframework.com/docs/theming/
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';

@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';


.isDesktop {
    ion-modal {
        --width: 50%;
        --height: 100%;
    }
}

ion-modal {
    //--width: 100% !important;
    //--height: 100% !important;
}

.alert-wrapper.sc-ion-alert-md {
    max-width: 100%;
}

.alert-tappable.alert-radio {
    height: auto;
    contain: content;
}

.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios {
    white-space: normal;
}

.ion-page {
    justify-content: flex-start;
}

ion-toast .toast-button-group {
    display: flex;
    flex-direction: column;
    color:blue;
}

ion-item.item-has-focus > ion-label,
.item-has-focus .label-floating.sc-ion-label-md-h:not(.ion-color){
    color: rgb(16, 16, 159) !important;
}

.alert-button.sc-ion-alert-md {
    color: black;
    font-weight: 600;
}